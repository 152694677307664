import ProgramCard from "app/components/ProgramCard";
import { ProgramCardSkeleton } from "app/components/ProgramCard/ProgramCardSkeleton";
import { Slider } from "app/components/Slider";
import { Routes } from "constants/routes";
import React from "react";
import { Section } from "../Section";

type DanceProgramsProps = {
  data: object[];
  loading?: boolean;
};
export const DancePrograms = ({ data, loading }: DanceProgramsProps) => {
  return (
    <Section
      title="Dance Programs"
      showLoadingState={loading}
      showTitleLoadingState
      loadingStateComponent={<LoadingStateComponent />}
      headerLink={{
        route: Routes.library__programs,
        text: "See All",
      }}
    >
      <Slider
        data={data}
        slideComponent={ProgramCard}
        selectedFrom="DancePrograms"
        arrowTopPosition="35%"
        slidesSpacing={32}
        slidesWidth={280}
      />
    </Section>
  );
};

function LoadingStateComponent() {
  return (
    <Slider
      data={[{}, {}, {}, {}, {}]}
      slideComponent={ProgramCardSkeleton}
      selectedFrom="DancePrograms"
      arrowTopPosition="35%"
      slidesSpacing={32}
      slidesWidth={280}
    />
  );
}
